import { useState } from "react"
import { useTranslation } from "react-i18next"

export const LanguageSelector = () => {
    const { i18n } = useTranslation()
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
    
    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language)
        i18n.changeLanguage(language)
    }

    return (
        <div className="flex gap-4">
            <button
                className={`${selectedLanguage === "en" ? "font-bold text-purple-300" : ""}`}
                onClick={() => handleLanguageChange("en")}
            >
                EN
            </button>
            <button
                className={`${selectedLanguage === "es" ? "font-bold text-purple-300" : ""}`}
                onClick={() => handleLanguageChange("es")}
            >
                ES
            </button>
        </div>
    )
}
